import CurrencyAmount from './CurrencyAmount';
import CurrencyIcon from './CurrencyIcon';
import CurrencyName from './CurrencyName';
import CurrencyPrice from './CurrencyPrice';

export default {
	Icon: CurrencyIcon,
	Name: CurrencyName,
	Price: CurrencyPrice,
	Amount: CurrencyAmount,
};
